<template>
  <Modal title="Versions" confirm-label="Got it!" no-cancel @confirm="$modal.close">
    <div class="space-y-6">
      <div class="rounded-md border-2 border-pink-500 px-5 py-4 text-center text-sm font-medium text-supplement">
        https://inspires.to/s/<strong class="text-pink-500">unique</strong>/your-content-experience
      </div>
      <p>
        Enhance your content experiences with versions. Each version you create comes with its own unique and shareable URL. This feature enables you to use the
        same content experience while tailoring your personal message and content specifically for your intended guests.
      </p>
      <div class="flex items-center space-x-6">
        <div><Button icon="pencil" color="pink" :ui="{ base: 'p-0 h-10 w-10' }" /></div>
        <p>Look out for personalize buttons within your content experience to add unique elements (photos, messages) for even greater impact.</p>
      </div>
    </div>
  </Modal>
</template>
